import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import 'antd/dist/antd.css';
import { Row, Col } from 'antd';

export default class IndexPage extends React.Component {
	render() {
		return (
			<Layout>
				<SEO title="Dra. Verónica Suzuki - Médica especialista en Ginecologia" />
				<div>
					<h2 className="intro">Contacto Web</h2>
					<p className="intro">Por favor complete el formulario y escriba su consulta.</p>

					<form
						onSubmit={this.handleSubmit}
						name="vero-contacto-pacientes"
						method="POST"
						data-netlify="true"
						action="/form-success"
						className="form-contacto"
					>
						<input type="hidden" name="form-name" value="vero-contacto-pacientes" />

						<Row gutter={16}>
							<Col span={24}>
								<label>
									<span className="input-label">Nombre y Apellido</span>:{' '}
									<input className="ant-input ant-input-lg" type="text" name="fullname" required />
								</label>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={12}>
								<label>
									<span className="input-label">Email</span>:
									<input className="ant-input ant-input-lg" type="email" name="email1" required />
								</label>
							</Col>
							<Col span={12}>
								<label>
									<span className="input-label">Confirmar Email</span>:{' '}
									<input className="ant-input ant-input-lg" type="email" name="email2" required />
								</label>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={12}>
								<label>
									<span className="input-label">Teléfono</span>:{' '}
									<input className="ant-input ant-input-lg" type="tel" name="phone1" required />
								</label>
							</Col>
							<Col span={12}>
								<label>
									<span className="input-label">Confirmar Teléfono</span>:{' '}
									<input className="ant-input ant-input-lg" type="tel" name="phone2" required />
								</label>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={24}>
								<label>
									<span className="input-label">Consulta</span>:{' '}
									<textarea className="ant-input" name="message" rows="10" cols="30" required />
								</label>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={24}>
								<button className="ant-btn ant-btn-primary ant-btn-lg" type="submit">
									Enviar
								</button>
							</Col>
						</Row>
					</form>
				</div>
			</Layout>
		);
	}
}

// const IndexPage = () => (
//  <div>
//    Content
//  </div>
// );
// export default IndexPage;
